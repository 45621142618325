.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* General Css */

.main-header {
  backdrop-filter: blur(12px);
}
.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
}

.two-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
}

.three-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
}

.four-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  margin-right: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #c7c7c7; 
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* @media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1440px !important;
  }
} */

/* General Css */

.banner-img {
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}

.banner-img:hover {
  opacity: 0.8;
  transform: translateY(-4px);
}

.product-action {
  margin-bottom: -8px;
  opacity: 0;
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  backdrop-filter: blur(4px);
}

.product-card:hover .product-action {
  margin-bottom: 0px;
  padding-top: 0px;
  opacity: 1;
  backdrop-filter: blur(4px);
}


/* Sevel Cols Categories */


@media (max-width: 768px){
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1  {
    width: 25%;
    /* width: 100%; */
  }

  .seven-cols .col-md-1 p,
  .seven-cols .col-sm-1 p,
  .seven-cols .col-lg-1 p  {
    font-size: small;
  }
}


@media (min-width: 992px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    /* *width: 14.285714285714285714285714285714%; */
  }
}


@media (min-width: 1200px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    /* *width: 14.285714285714285714285714285714%; */
  }
}

/* Sevel Cols Categories */


.main-img, .hover-img {
  transition: opacity .3s ease-in-out;
}




/* Blog Card */

.blog-banner {
  transition: 0.3s ;
}

.blog-card:hover .blog-banner {
  transform: scale(1.1);
}

/* Blog Card */


/* Carousel Product */

.Product-carousel {
  position: sticky !important;
  top: 200px !important;
}

.thumb {
 border-radius: 8px;
 border: 1px solid #ececec !important
}

.thumb img {
  border-radius: 8px;
}

.thumb.selected {
  border: 3px solid var(--bs-primary) !important
}

.thumbs {
  transition-duration: 100ms !important;
  padding: 0 !important;
}

.thumbs-wrapper {
  margin: 20px 0px !important;
}

.carousel .control-dots .dot {
  background: var(--bs-primary) !important;
  box-shadow: none !important;
}



/* Carousel Product */


/* List Radio */

.list-group-radio .list-group-item {
  cursor: pointer;
  border-radius: .5rem;
}
.list-group-radio .form-check-input {
  z-index: 2;
  margin-top: -.5em;
}
.list-group-radio .list-group-item:hover,
.list-group-radio .list-group-item:focus {
  background-color: var(--bs-secondary-bg);
}

.list-group-radio .form-check-input:checked + .list-group-item {
  /* background-color: var(--bs-secondary-bg-subtle); */
  border-color: var(--bs-secondary);
  box-shadow: 0 0 0 2px var(--bs-secondary);
}
.list-group-radio .form-check-input[disabled] + .list-group-item,
.list-group-radio .form-check-input:disabled + .list-group-item {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

/* List Radio */


/* Horizontal Scrolling */

.horizontal-scroll {
  flex-wrap: nowrap !important;
  overflow-y: scroll;
}


.horizontal-scroll::-webkit-scrollbar {
  width: 4px; 
}

.horizontal-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #f36f21;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: var(--bs-primary);
  border-radius: 8px;
  border: 1px solid var(--bs-primary);
  box-shadow: none;
  max-height: 33%;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background: var(--bs-primary);
  background-clip: padding-box;
  border: 4px solid var(--bs-primary);
  border-radius: 8px;
  box-shadow: none;
  min-height: 50px;
}

.horizontal-scroll::-webkit-scrollbar-track, .horizontal-scroll::-webkit-scrollbar-track:hover {
  background: #f1f1f1;
  border-radius: 50px;
  border: none;
  margin-right: 200px;
  margin-left: 200px;
}

/* Horizontal Scrolling */

/* .Categories {
  background-image: linear-gradient(30deg, #ffffff 12%, transparent 12.5%, transparent 87%, #ffffff 87.5%, #ffffff), linear-gradient(150deg, #ffffff 12%, transparent 12.5%, transparent 87%, #ffffff 87.5%, #ffffff), linear-gradient(30deg, #ffffff 12%, transparent 12.5%, transparent 87%, #ffffff 87.5%, #ffffff), linear-gradient(150deg, #ffffff 12%, transparent 12.5%, transparent 87%, #ffffff 87.5%, #ffffff), linear-gradient(60deg, rgba(255,255,255,0.5) 25%, transparent 25.5%, transparent 75%, rgba(255,255,255,0.5) 75%, rgba(255,255,255,0.5)), linear-gradient(60deg, rgba(255,255,255,0.5) 25%, transparent 25.5%, transparent 75%, rgba(255,255,255,0.5) 75%, rgba(255,255,255,0.5));
    background-size: 34px 59.5px;
    background-position: 0 0, 0 0, 17px 29.75px, 17px 29.75px, 0 0, 17px 29.75px;
    background-color: hsl(78 40% 96% / 1);
} */
.site_menu_link{
  -webkit-text-decoration:none !important;
  text-decoration: none !important;
  color: black !important;
}